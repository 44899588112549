<template>
  <div>
    <router-view v-show='$route.matched.length==3'></router-view>
    <PageHeaderLayout v-show='$route.matched.length==2'>
      <div class='main-page-content'>
        <el-row class='table-header'>
          <el-col :span='20'>
            <div style='display: inline'>
              <el-form :model='searchCondition' :inline='true' label-position='right' label-width=''>
                <el-form-item label=''>
                  <CompanySelect :multiple="true" @handleSelect='selectDept'/>
                </el-form-item>
                <el-form-item label=''>
                  <GroupSelect :group='searchCondition.group_id' @change='selectGroup'></GroupSelect>
                </el-form-item>
                <el-form-item label='' prop='status'>
                  <el-checkbox-group v-model='searchCondition.sign_status'>
                    <el-checkbox label='Y'>签约</el-checkbox>
                    <el-checkbox label='N'>解约</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label='' prop='email'>
                  <el-input v-model='searchCondition.nickname' placeholder='红人昵称模糊匹配' clearable></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type='primary' @click='searchForm'><i class='el-icon-search'></i>搜索</el-button>
                  <el-button @click='clearCondition'>全部清除</el-button>
                  <el-button type='text' @click='searchButton'>更多筛选条件</el-button>
                </el-form-item>
              </el-form>
            </div>

          </el-col>
          <el-col :span='4' style='text-align: right'>
            <!--            涨粉数据录入-->
            <el-button type='primary' size='medium' icon='iconfont'
                       v-if="userPermissions.indexOf('trend_list') !== -1"
                       @click="goToPage('/trend/enter')">涨粉录入
            </el-button>
            <el-button type='primary' size='medium' icon='iconfont'
                       v-if="userPermissions.indexOf('revenue_list') !== -1"
                       @click="goToPage('/revenue/enter')">营收录入
            </el-button>
          </el-col>
        </el-row>
        <br />
        <!--   table列表     -->
        <div class='default-table'>
          <ApeTable
            ref='apeTable'
            :data='dataList'
            :columns='columns'
            :loading='loadingStatus'
            :pagingData='pagingData'
            @switchPaging='switchPaging'
            @changeTableSort='changeTableSort'
            highlight-current-row
            border>
            <el-table-column
              slot='second-column'
              width='64'
              label='序号' align='center' fixed='left'>
              <template slot-scope='scope'>
                <span>{{ offset + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              width='120'
              label='操作' align='center' fixed='left'>
              <template slot-scope='scope'>
              <span>
                <el-tooltip effect='dark' content='编辑' placement='top-start'
                            v-if="userPermissions.indexOf('artist_edit') != -1">
                  <el-link type='primary' size='mini' icon='el-icon-edit' @click='editButton(scope.row.id)'>编辑</el-link>
                </el-tooltip>
              </span>
              </template>
            </el-table-column>
            <el-table-column slot='second-column' prop='nickname' label='红人昵称' min-width='120' align='center'
                             show-overflow-tooltip
                             sortable='custom'>
              <template slot-scope='{row}'>
                <artist-nickname :info='{artist_id:row.id,nickname:row.nickname}' />
              </template>
            </el-table-column>
            <el-table-column slot='second-column' prop='group_id' label='项目组' min-width='120' align='center'
                             show-overflow-tooltip
                             sortable='custom'>
              <template slot-scope='{row}'>
                <span>{{ row.group_name || row.group_id }}</span>
              </template>
            </el-table-column>
            <el-table-column prop='sign_status' label='签约状态' min-width='100' align='center'
                             show-overflow-tooltip
                             sortable='custom'>
              <template slot-scope='{row}'>
                <span>{{ row.sign_status_alias }}</span>
              </template>
            </el-table-column>
            <el-table-column prop='sign_start' label='签约时间' min-width='100' align='center'
                             show-overflow-tooltip
                             sortable='custom'>
              <template slot-scope='{row}'>
                <span>{{ row.sign_start }}</span>
              </template>
            </el-table-column>
            <el-table-column prop='sign_end' label='合约到期' min-width='100' align='center'
                             show-overflow-tooltip
                             sortable='custom'>
              <template slot-scope='{row}'>
                <span>{{ row.sign_end }}</span>
              </template>
            </el-table-column>
            <el-table-column prop='fired_at' label='解约时间' min-width='100' align='center'
                             show-overflow-tooltip
                             sortable='custom'>
              <template slot-scope='{row}'>
                <span>{{ row.fired_at }}</span>
              </template>
            </el-table-column>
            <el-table-column prop='group_time' label='入组时间' min-width='100' align='center'
                             show-overflow-tooltip
                             sortable='custom'>
              <template slot-scope='{row}'>
                <span>{{ row.group_time }}</span>
              </template>
            </el-table-column>
            <el-table-column prop='created_at' label='录入时间' min-width='100' align='center'
                             show-overflow-tooltip
                             sortable='custom'>
              <template slot-scope='{row}'>
                <span>{{ row.created_at }}</span>
              </template>
            </el-table-column>
            <el-table-column prop='updated_at' label='修改时间' min-width='100' align='center'
                             show-overflow-tooltip
                             sortable='custom'>
              <template slot-scope='{row}'>
                <span>{{ row.updated_at }}</span>
              </template>
            </el-table-column>
          </ApeTable>
        </div>

        <!--   抽屉查询     -->
        <ApeDrawer :drawerData='drawerData' @drawerClose='drawerClose' @drawerConfirm='drawerConfirm'>
          <template slot='ape-drawer'>
            <el-form :model='searchCondition' ref='userForm' label-position='right' label-width='96px'>
              <el-row>
                <el-col :span='22'>
                  <el-form-item label='平台'>
                    <el-checkbox-group v-model='searchCondition.platforms'>
                      <el-checkbox v-for='item in platforms' :label='item.code' :value='item.code' :key='item.code'>
                        {{ item.name }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span='22'>
                  <el-form-item label='分组'>
                    <CompanySelect ref='refDeptCascader' :multiple="true"
                                   @handleSelect='selectDept($event,"cascader")'/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span='22'>

                  <el-form-item label='签约状态' prop='status'>
                    <el-checkbox-group v-model='searchCondition.sign_status'>
                      <el-checkbox label='Y'>签约</el-checkbox>
                      <el-checkbox label='N'>解约</el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span='22'>
                  <el-form-item label='红人昵称' prop='email'>
                    <el-input v-model='searchCondition.nickname' placeholder='红人昵称模糊匹配' clearable></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span='22'>
                  <el-form-item>
                    <el-button type='primary' @click='searchForm'>筛选</el-button>
                    <el-button @click='clearCondition'>全部清除</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </template>
        </ApeDrawer>
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import ApeDrawer from '@/components/ApeDrawer'
import GroupSelect from '@/pages/groups/components/GroupSelect'

export default {
  name: 'ArtistList',
  components: {
    PageHeaderLayout,
    ApeTable,
    ApeDrawer, GroupSelect
  },
  data() {
    return {
      orderSort: { id: 'desc' },//默认排序规则
      loadingStatus: true,
      // 表格列表数据
      dataList: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 15
      },
      // 分页的offset,序号列使用
      offset: 0,
      columns: [],
      platforms: [],
      groupOptions: [],
      // 抽屉数据
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        width_height: '480px',
        show_footer: false
      },
      searchCondition: {
        nickname: null,
        group_id: null,
        sign_status: [],
        platforms: []
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  created() {
    // 平台
    // console.log(this.$route.matched)
    if (this.$route.matched.length === 2) {
      this.init()
    }
  },
  mounted() {

  },
  watch: {
    '$route.matched': function(n) {
      if (n.length === 2) {
        this.init()
      }
    }
  },
  methods: {
    init() {
      this.getColumns()
      this.groupList()
      this.getPlatforms()
      setTimeout(() => {
        this.initList()
      }, 800)

    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.initList()
    },
    goToPage(routeName) {
      this.$router.push({ path: routeName })
    },
    async groupList() {
      let { list } = await this.$api.getGroupList()
      this.groupOptions = list
    },
    async getPlatforms() {
      //从缓存中读取上次的平台信息
      // let platformsJson = localStorage.getItem('platforms')
      // if (platformsJson) {
      //   this.platforms = JSON.parse(platformsJson)
      // }
      // 获取最新平台信息
      let { list } = await this.$api.getPlatforms()
      this.platforms = list
      // 缓存平台信息
      localStorage.setItem('platforms', JSON.stringify(list))

    },
    async getColumns() {
      let { columns } = await this.$api.getColumnsArtist()
      this.columns = columns
    },
    // 初始化列表
    async initList(type) {
      this.loadingStatus = true
      let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
      let searchCondition = this.handleSearchCondition()
      // Object.assign(searchCondition, pagingInfo)
      Object.assign(searchCondition, pagingInfo, { order: this.orderSort })
      this.dataList = []
      let { list, pages } = await this.$api.getArtistList(searchCondition)
      this.$nextTick(() => {
        this.dataList = list
      })
      this.pagingData.total = pages.total
      this.offset = pages.offset
      this.loadingStatus = false
    },
    // 切换页码操作
    async switchPaging() {
      await this.initList()
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      if (this.searchCondition.platforms) {
        condition.platforms = this.searchCondition.platforms
      }
      if (this.searchCondition.group_id) {
        condition.group_id = this.searchCondition.group_id
      }
      if (this.searchCondition.nickname) {
        condition.nickname = this.searchCondition.nickname
      }
      if (this.searchCondition.sign_status.length > 0) {
        condition.sign_status = this.searchCondition.sign_status
      }

      if (this.searchCondition.time_value) {
        condition.start_time = this.searchCondition.time_value[0]
        condition.end_time = this.searchCondition.time_value[1]
      }
      if (this.searchCondition.group_range?.length != 0) {
        condition.group_range = this.searchCondition.group_range
      }

      return condition
    },
    searchButton() {
      this.drawerData.loading_text = '玩命加载中……'
      this.drawerData.visible = true
      this.drawerData.title = '查询条件'
      this.$nextTick(() => {
        this.drawerData.loading = false
      })
    },
    // 响应添加按钮
    addButton() {
      this.$router.push({
        path: this.$route.path + '/create'
      })
    },
    // 响应编辑按钮
    detailButton(id) {
      this.$router.push(this.$route.path + '/' + id + '/intro')
    },
    // 响应编辑按钮
    editButton(id) {
      this.$router.push(this.$route.path + '/' + id + '/edit')
    },


    // 处理抽屉关闭
    drawerClose() {
      this.drawerData.visible = false
    },
    // 处理抽屉确认
    async drawerConfirm() {
      this.searchForm()
    },
    // 搜索
    searchForm() {
      this.drawerData.visible = false
      this.offset = 0
      this.initList()
      // this.$refs['apeTable'].handleCurrentChange(1)
    },
    clearCondition() {
      this.searchCondition = {
        nickname: null,
        group_id: null,
        sign_status: 'Y',
        platforms: [],
        group_range: []
      }
      this.searchForm()
    },
    async selectDept(val, type = null) {
      // let group_id = null
      this.searchCondition.group_range = val
      this.searchCondition.group_id = null
      if (!type) {
        this.searchForm()
      }

    },
    selectGroup(val) {
      this.searchCondition.group_id = val
      this.searchCondition.group_range = null
      this.searchForm()
    }
  }
}
</script>

<style lang='stylus'>

</style>
