<template>
  <div>
    <el-select v-bind="$attrs" :value="group_id" :placeholder="`MCN组别：${myGroupName}`" filterable
        style="width: 100%" @change="choiceGroup" clearable>
      <el-option :label="`${item.display_name}`" :value="item.name"
          v-for="item in groupOptions"
          :key="item.name"
          :disabled="item.disabled">
        <span style="float: left">{{ item.display_name }}</span>
        <span style="float: right">{{ item.name }}</span>
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "GroupSelect",
  data() {
    return {
      group_id: null,
      myGroupName: '',
      groups: [],
      scopeGroups: []
    }
  },
  props: {
    groupsAllow: {
      type: Array,
      default() {
        return []
      }
    },
    group: {
      type: String,
      default: ""
    },
    autoInit: {
      type: Boolean,
      default() {
        return true;
      }
    },
    scopeType: {
      type: String,
      default() {
        return 'all'
      }
    }
  },
  computed: {
    ...mapGetters(['userDptInfo']),
    // 表格数据
    groupOptions() {
      // console.log('scopeGroups', this.scopeGroups)
      let options = []
      this.groups.forEach(( item ) => {
        if (this.scopeType === 'all') {
          options.push({name: item.name, display_name: item.display_name, disabled: false});
        } else {
          let disabled = this.scopeGroups.indexOf(item.name) === -1;
          options.push({name: item.name, display_name: item.display_name, disabled: disabled})
        }
      })
      return options
    },
  },
  watch: {
    group( val, oldVal ) {
      // console.log(oldVal)
      if (val !== oldVal)
        this.group_id = val
    },
    scopeType( val ) {
      switch (val) {
        case 'all':
          this.scopeGroups = []
          break;
        case 'dpt':
          this.scopeGroups = this.userDptInfo.dpt_range
          break;
        case 'group':
          this.scopeGroups = this.userDptInfo.my_dpt_range
          break
      }
    }
  },
  created() {

  },
  mounted() {
    this.groupList()
    // this.getMyGroup()
  },
  methods: {
    async groupList() {
      let {list} = await this.$api.getGroupList()
      this.groups = list
      if (this.autoInit) {
        this.$nextTick(() => {
          this.getMyGroup()
        })
      }

    },
    async getMyGroup() {
      try {
        let dpt_ids = this.userDptInfo.my_dpt_range ? this.userDptInfo.my_dpt_range : []
        if (dpt_ids && dpt_ids.length > 0) {
          for (let i in dpt_ids) {
            let dpt_id = dpt_ids[i]
            let group = this.groups.find(( value ) => value.name === dpt_id)
            if (group) {
              this.myGroupName = group.display_name;
              this.$emit('currentSelect', group);
              break
            }
          }
        }
        // 若本人所在部门不在分组内容，则默认选中第一个
        if (!this.myGroupName) {
          let group = this.groups[0]
          // console.log(group)
          this.$emit('currentSelect', {name: group['name'], display_name: group['display_name']});
        }

      } catch (e) {
        console.log(e)
      }

    },
    choiceGroup( val ) {
      // console.log('sel', val)
      this.$emit('change', val);
      let group = this.groups.find(( value ) => value.name === val)
      this.$emit('currentGroup', group);
    }
  }
}
</script>

<style scoped>
.el-select > .el-input {
  width: 200px !important;
}
</style>
